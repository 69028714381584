<template>
  <div class="operating_container">
    <div class="crumbs operating_item">
      <el-breadcrumb separator-icon="ArrowRight">
        <el-breadcrumb-item :to="{ path: '/workbench' }">
          <i class="iconfont icon-zhuye"></i
        ></el-breadcrumb-item>
        <el-breadcrumb-item>日前交易</el-breadcrumb-item>
        <el-breadcrumb-item>运行日对比</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="operating_item flex_between">
      <div class="flex_start operation">
        <div class="flex_start">
          <span>日期选择：</span>
          <el-date-picker
            v-model="dateTime"
            type="date"
            placeholder="请选择"
            @change="changeType"
          />
        </div>
      </div>
    </div>

    <div class="operating_box">
      <div class="forecast">
        <div class="operating_title">电网运行预测信息</div>
        <div class="forecast_container">
          <div
            class="forecast_item"
            v-for="(item, index) in forecastMsg.data"
            :key="index"
          >
            <div>{{ item.label }}</div>
            <div>{{ item.value }}</div>
          </div>
        </div>
        <div class="forecast_echarts">
          <div class="flex_between">
            <el-tabs
              v-model="paneActive"
              class="forecast_tabs"
              @tab-click="handlePane"
            >
              <el-tab-pane
                :label="item.name"
                :name="item.id"
                v-for="(item, index) in paneList.data"
                :key="index"
              ></el-tab-pane>
            </el-tabs>

            <el-radio-group
              v-model="forecastType"
              size="large"
              @change="changeForecastType"
            >
              <el-radio-button label="预测" />
              <el-radio-button label="实际" />
            </el-radio-group>
          </div>

          <div class="" id="forecastEchart" ref="forecastEchart"></div>
        </div>
      </div>

      <div class="similar">
        <div class="operating_title">相似日匹配</div>
        <div class="similar_container">
          <div class="similar_item">
            <div class="flex_start similar_box">
              <div class="similar_title">匹配依据</div>
              <el-select v-model="basis" placeholder="">
                <el-option
                  v-for="item in paneList.data"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </div>
            <div class="flex_start similar_box">
              <div class="similar_title">匹配时段</div>
              <el-date-picker
                class="similar_date"
                v-model="dateData.data"
                type="daterange"
                range-separator="-"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
              />
            </div>
            <div class="flex_start similar_box">
              <div class="similar_title">匹配方法</div>
              <el-radio-group v-model="means">
                <el-radio :label="1">均值</el-radio>
                <el-radio :label="2">最大值</el-radio>
                <el-radio :label="3">最小值</el-radio>
              </el-radio-group>
            </div>
            <div class="flex_start similar_box">
              <div class="similar_title">分析维度</div>
              <el-radio-group v-model="dimension">
                <el-radio :label="1">预测</el-radio>
                <el-radio :label="2">实际</el-radio>
              </el-radio-group>
            </div>
            <div class="flex_end similar_box">
              <el-button type="primary">查询</el-button>
            </div>
          </div>

          <div
            class="similar_item"
            v-for="(item, index) in weekdayList.data"
            :key="index"
          >
            <div class="weekday_box similar_box">
              <span>{{ item.date }} 工作日</span>
            </div>
            <div class="flex_between similar_box">
              <div class="deviation flex_start">
                <span>统调偏差比</span>
                <span class="deviation_val">{{ item.track }}</span>
              </div>
              <div class="deviation flex_start">
                <span>统调偏差值</span>
                <span class="deviation_val">{{ item.val }}</span>
              </div>
            </div>
            <div class="average">
              <div class="flex_between"> 
                <div class="average_title">
                  平均日前价格（元/MWh）<br /><span class="average_title_val">{{
                    item.aday
                  }}</span>
                </div>
                <div class="average_title">
                  平均实时价格（元/MWh）<br /><span class="average_title_val">{{
                    item.real
                  }}</span>
                </div>
              </div>

              <div
                :id="'weekday' + index"
                :ref="'weekday' + index"
                class="weekday"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, getCurrentInstance, nextTick, onMounted } from "vue";
export default {
  name: "operatingDay",
  setup() {
    const { proxy } = getCurrentInstance(); // proxy是组件实例化对象

    let dateTime = ref(new Date());

    let forecastMsg = reactive({
      data: [
        { label: "统调预测（MWh）", value: "10,908,830" },
        { label: "西电预测（MWh）", value: "2,238,867" },
        { label: "B类预测（MWh）", value: "6,867,745" },
        { label: "平均温度（C°）", value: "28.3" },
      ],
    });

    let paneActive = ref(1);
    let paneList = reactive({
      data: [
        { id: 1, name: "统调" },
        { id: 2, name: "西电" },
        { id: 3, name: "B类" },
        { id: 4, name: "需供比" },
        { id: 5, name: "温度" },
      ],
    });

    let forecastType = ref("预测");

    let basis = ref(1);

    let means = ref(1);
    let dimension = ref(1);

    let dateData = reactive({ data: [] });

    const changeType = (val) => {
      forecastChart();
      weekdayChart();
    };

    let forecast = reactive({
      options: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
          formatter: function (params) {
            let back = params[0].name;
            for (let i = 0, l = params.length; i < l; i++) {
              back +=
                "<br/>" +
                params[i].marker +
                params[i].seriesName +
                "  " +
                params[i].value +
                "万";
            }
            return back;
          },
        },
        legend: {
          data: ["预测"],
          textStyle: {
            color: "#FFFFFF",
          },
        },
        grid: {
          left: "0%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },

        xAxis: [
          {
            type: "category",
            data: [],
            axisTick: {
              alignWithLabel: true,
            },

            boundaryGap: false,
            axisLabel: {
              //轴上的字 字体、样式
              interval: 0, //代表显示所有x轴标签显示
              rotate: 45, //代表逆时针旋转45度
              textStyle: {
                color: "#FFFFFF",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "单位：MW",
            nameTextStyle: {
              color: "#FFFFFF",
              align: "left",
            },
            axisLine: { onZero: false, show: true },
            axisLabel: {
              //轴上的字 字体、样式
              textStyle: {
                color: "#FFFFFF",
              },
              formatter: "{value} 万",
            },
            splitLine: {
              //修改背景线条样式
              show: true, //是否展示
              lineStyle: {
                color: "#8291A9", //线条颜色
                type: "dashed", //线条样式，默认是实现，dashed是虚线
              },
            },
          },
        ],
        series: [
          {
            type: "line",
            name: "预测",
            barWidth: 14,
            data: [],
            showSymbol: false,
            smooth: false,
            itemStyle: {
              normal: {
                color: "#09D3D5",
              },
            },
          },
        ],
      },
    });

    const forecastChart = async () => {
      // 渲染图表
      const chart = proxy.$echarts.init(
        document.getElementById("forecastEchart")
      );
      let titles = [
        "00:00",
        "01:15",
        "02:30",
        "03:45",
        "04:00",
        "05:10",
        "06:30",
        "07:45",
        "08:00",
        "09:15",
        "10:30",
        "11:45",
        "12:00",
        "13:15",
        "14:30",
        "15:45",
        "16:00",
        "17:15",
        "18:30",
        "19:45",
        "20:00",
        "21:15",
        "22:30",
        "23:45",
      ];

      let array = [];
      for (let i = 0; i < titles.length; i++) {
        array.push(Math.trunc(Math.random() * 100));
      }

      forecast.options.series[0].data = array;

      forecast.options.xAxis[0].data = titles;
      chart.setOption(forecast.options);
      window.onresize = () => {
        chart.resize();
      };
    };

    const handlePane = () => {
      console.log(paneActive.value);
      forecastChart();
    };
    const changeForecastType = () => {
      console.log(forecastType.value);
      forecast.options.legend.data = [forecastType.value];
      forecast.options.series[0].name = forecastType.value;
      console.log(forecast);
      nextTick(() => {
        forecastChart();
      });
    };

    let weekdayList = reactive({
      data: [
        {
          date: "2022-08-25",
          aday: 636.0183,
          real: 792.4384,
          track: "0.25%",
          val: "282.5",
        },
        {
          date: "2022-08-17",
          aday: 758.0278,
          real: 705.4343,
          track: "1.17%",
          val: "1313.3854",
        },
      ],
    });
    let weekday = reactive({
      options: {
        grid: {
          left: "0%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },

        xAxis: [
          {
            type: "category",
            data: [],
            axisTick: {
              alignWithLabel: false,
            },
            axisLine: {
              show: false, //隐藏y轴
            },

            boundaryGap: false,
          },
        ],
        yAxis: [
          {
            type: "value",

            splitLine: {
              //修改背景线条样式
              show: false, //是否展示
            },
            axisTick: {
              show: false, //刻度线
            },
            axisLine: {
              show: false, //隐藏y轴
            },
            axisLabel: {
              show: false, //隐藏刻度值
            },
          },
        ],
        series: [
          {
            type: "line",
            name: "日前",
            data: [],
            showSymbol: false,
            smooth: false,
            itemStyle: {
              normal: {
                color: "#FF3D57",
              },
            },
          },
          {
            type: "line",
            name: "实时",
            data: [],
            showSymbol: false,
            smooth: false,
            itemStyle: {
              normal: {
                color: "#09D3D5",
              },
            },
          },
        ],
      },
    });

    const weekdayChart = async () => {
      // 渲染图表

      weekdayList.data.forEach((val, index) => {
        let ids = "weekday" + index;
        const chart = proxy.$echarts.init(document.getElementById(ids));
        let array = [],
          array2 = [];
        for (let i = 0; i < 24; i++) {
          array.push(Math.trunc(Math.random() * 50 - 45));
          array2.push(Math.trunc(Math.random() * 50 - 50));
        }
        weekday.options.series[0].data = array;
        weekday.options.series[1].data = array2;
        chart.setOption(weekday.options);
        window.onresize = () => {
          chart.resize();
        };
      });
    };

    onMounted(() => {
      nextTick(() => {
        forecastChart();
        weekdayChart();
      });
    });
    return {
      dateTime,
      changeType,
      forecastMsg,
      paneActive,
      paneList,
      forecastType,
      forecast,
      forecastChart,
      handlePane,
      changeForecastType,
      basis,
      means,
      dimension,
      weekday,
      weekdayChart,
      weekdayList,
      dateData,
    };
  },
};
</script>

<style lang="scss" scoped>
.operating_box {
  display: flex;
  justify-content: space-between;
}
.forecast {
  width: 1126px;
}
.similar {
  width: 485px;

  .similar_container {
    width: 100%;
    background: #0b183c;
    box-shadow: inset 0px 0px 10px 0px #3767ad;
    border-radius: 8px;
    .similar_item {
      padding: 24px 30px;
      border-bottom: 1px solid #293657;
      .similar_box {
        margin-bottom: 20px;
      }
      &:deep(.el-input__wrapper) {
        background: rgba(2, 86, 255, 0.1);

        box-shadow: none;
        width: 220px;
      }
      &:deep(.el-input__inner) {
        color: #ffffff;
      }
      &:deep(.el-radio) {
        color: #ffffff;
      }
      &:deep(.el-date-editor .el-range-input) {
        color: #ffffff;
      }
      .similar_title {
        font-size: 14px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        margin-right: 10px;
      }

      //工作日模块
      .weekday_box {
        font-size: 14px;
        font-family: Poppins-Medium, Poppins;
        font-weight: 500;
        color: #ffffff;
        width: 100%;
      }
      .deviation {
        font-size: 12px;
        font-family: Poppins-Regular, Poppins;
        font-weight: 400;
        color: #ffffff;
        .deviation_val {
          font-size: 18px;
          font-family: Poppins-Bold, Poppins;
          font-weight: bold;
          color: #0081ff;
          padding-left: 10px;
        }
      }
      .average {
        border: 1px solid #293657;
        padding: 16px;
        // width: 100%;
        .average_title {
          font-size: 14px;
          font-family: Poppins-Regular, Poppins;
          font-weight: 400;
          color: #ffffff;
          line-height: 20px;
          .average_title_val {
            font-size: 18px;
            font-family: Poppins-Medium, Poppins;
            font-weight: 500;
            color: #ffffff;
          }
        }
      }
    }
    .similar_item:last-child {
      border: none;
    }
  }
}
.operating_title {
  margin: 16px 0;
  font-size: 16px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #ffffff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-indent: 1em;

  &::before {
    display: block;
    content: "";
    width: 6px;
    height: 20px;
    background: linear-gradient(135deg, #0081ff 0%, #22cce2 100%);
    border-radius: 3px 3px 3px 3px;
  }
}

.operating_container {
  color: #ffffff;
  display: flex;
  flex-direction: column;
  .operating_item {
    width: 100%;
    margin-bottom: 24px;

    .operation {
      font-size: 14px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;

      &:deep(.el-input__wrapper) {
        background: #1f2935;
        box-shadow: none;
      }
      &:deep(.el-input__inner) {
        color: #ffffff;
      }
    }
  }
  .crumbs {
    font-size: 14px;
    &:deep(.el-breadcrumb__item:last-child .el-breadcrumb__inner) {
      color: #ffffff;
    }
  }
}
.forecast_container {
  display: flex;
  justify-content: space-between;
}
.forecast_item {
  width: 220px;
  background: #0b183c;
  box-shadow: inset 0px 0px 10px 0px #3767ad;
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  padding: 24px 21px;
  border-radius: 8px;

  & > div {
    width: 100%;

    font-size: 14px;
    font-family: Poppins-Regular, Poppins;
    font-weight: 400;
    color: #ffffff;
  }
}
.forecast_item > div:last-child {
  font-size: 36px;
  font-family: D-DIN-Bold, D-DIN;
  font-weight: bold;
  color: #36d1ff;
  margin-top: 12px;
}
.forecast_echarts {
  width: 100%;
  background: #0b183c;
  box-shadow: inset 0px 0px 10px 0px #3767ad;
  padding: 24px;
  box-sizing: border-box;
  margin-top: 24px;
  border-radius: 8px;
}
.forecast_tabs {
  &:deep(.el-tabs__item) {
    color: #ffffff;
  }
  &:deep(.el-tabs__active-bar) {
    background: #36d1ff;
  }

  &:deep(.el-tabs__nav-wrap::after) {
    // width: 1000px;
    background: #8291a9;
    //  position: static !important;
  }
}
#forecastEchart {
  width: 100%;
  height: 300px;
}
.weekday {
  width: 100%;
  height: 100px;
}
</style>
